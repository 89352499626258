import React, { useContext } from 'react';
import Save from '@mui/icons-material/Save';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { AnswerContext } from '../../context/AnswerContext';
import CircularProgress from '@mui/material/CircularProgress';
import { defaultStyles } from '../../layout/styleProvider';
import { Button } from '@mui/material';

const SaveButton = () => {
  const { answerPersistenceState, handleSaveAnswers, answerCountUnsaved } =
    useContext(AnswerContext);
  const classes = defaultStyles();

  function renderSaveButton() {
    if (answerPersistenceState === 'unsaved') {
      return (
        <ListItem component="li" className={classes.mainNavigationItem}>
          <Button
            onClick={() => handleSaveAnswers()}
            sx={{
              textTransform: 'none',
              width: '100%',
              textAlign: 'left',
              minWidth: 'unset',
            }}
          >
            <ListItemIcon>
              <Save className={classes.mainNavigationSaveButtonSaveIcon} />
            </ListItemIcon>

            <ListItemText
              primary="Save Changes"
              secondary={'Changes: ' + answerCountUnsaved()}
              className={classes.mainNavigationSaveButtonText}
            />
          </Button>
        </ListItem>
      );
    } else if (answerPersistenceState === 'saving') {
      return (
        <ListItem component="li" className={classes.mainNavigationItemInactive}>
          <Button
            sx={{
              textTransform: 'none',
              textAlign: 'left',
              minWidth: 'unset',
            }}
            disabled
          >
            <ListItemIcon>
              <CircularProgress
                size={20}
                className={classes.mainNavigationSaveButtonCircularProgress}
              />
            </ListItemIcon>

            <ListItemText
              primary="Saving..."
              className={classes.mainNavigationSaveButtonText}
            />
          </Button>
        </ListItem>
      );
    } else if (answerPersistenceState === 'saved') {
      return (
        <ListItem component="li" className={classes.mainNavigationItemInactive}>
          <Button
            disabled
            sx={{
              textTransform: 'none',
              textAlign: 'left',
              minWidth: 'unset',
            }}
          >
            <ListItemIcon>
              <Save className={classes.mainNavigationSaveButtonSaveIcon} />
            </ListItemIcon>

            <ListItemText
              primary="No Changes"
              secondaryTypographyProps="color: white"
              className={classes.mainNavigationSaveButtonText}
            />
          </Button>
        </ListItem>
      );
    } else {
      return <>Error: {answerPersistenceState}</>;
    }
  }

  return (
    <List sx={{ '& .MuiButtonBase-root': { padding: '8px 16px' } }}>
      {renderSaveButton()}
    </List>
  );
};

export default SaveButton;
