import React, { useContext } from 'react';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import Explorer from '../explorer/Explorer';
import Messages from './Messages';

import QuestionnaireView from '../views/QuestionnaireView';
import AuditView from '../views/AuditView';

import { defaultStyles } from '../../layout/styleProvider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TopicView from '../views/TopicView';
import { PollContext } from '../../context/PollContext';
import { useAuth0 } from '@auth0/auth0-react';
import { getConfig } from '../../../config';
import packageInfo from '../../../../package.json';

import { ReactComponent as LogoDark } from './../../../assets/logo-dark.svg';
import { Box } from '@mui/material';

const Dashboard = () => {
  const { selectedQuestionnaire, selectedTopic, auditMode } =
    useContext(PollContext);
  const { logout } = useAuth0();

  const classes = defaultStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  function renderMainView() {
    if (selectedTopic) {
      return <TopicView />;
    } else if (selectedQuestionnaire) {
      return <QuestionnaireView />;
    } else if (auditMode) {
      return <AuditView />;
    } else {
      return (
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            Please select a Questionnaire on the left side.
          </Paper>
        </Grid>
      );
    }
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
            size="large"
          >
            <MenuIcon />
          </IconButton>

          <LogoDark className={classes.headerLogo} />

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
            style={{ marginTop: 3 }}
          >
            Poll
          </Typography>

          <Messages />

          <Button
            variant="contained"
            className={classes.button}
            color="inherit"
            sx={{ color: '#000' }}
            onClick={() =>
              logout({
                returnTo: getConfig('REACT_APP_AUTH0_LOGOUT_URL'),
              })
            }
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer
        data-testid="side-navigation"
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <Divider />

        <Explorer />
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="lg" className={classes.container}>
          {renderMainView()}
        </Container>

        <Grid container spacing={0}>
          <Grid
            container
            item
            xs={12}
            md={8}
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <List className={classes.footerLinks}>
              <ListItem component="li" className={classes.footerLink}>
                <a
                  href="https://www.techminers.com/imprint"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemText
                    primary="Imprint"
                    className={classes.footerText}
                  />
                </a>
              </ListItem>

              <ListItem component="li" className={classes.footerLink}>
                <a
                  href="https://www.techminers.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemText
                    primary="Privacy"
                    className={classes.footerText}
                  />
                </a>
              </ListItem>
              <ListItem component="li" className={classes.footerLink}>
                <a
                  href="https://www.techminers.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemText
                    primary={`© ${new Date().getFullYear()} - TechMiners`}
                    className={classes.footerText}
                  />
                </a>
              </ListItem>
            </List>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={4}
            justifyContent="flex-end"
            alignItems={'center'}
          >
            <ListItem component="li" className={classes.footerLink}>
              <Box
                sx={{
                  p: '12px',
                  opacity: 0.3,
                  fontSize: '14px',
                }}
              >
                POLL v{packageInfo.version}
              </Box>
            </ListItem>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default Dashboard;
