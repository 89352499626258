import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import CategoryIcon from '@mui/icons-material/Assignment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Title from '../dashboard/Title';
import { defaultStyles } from '../../layout/styleProvider';
import { PollContext } from '../../context/PollContext';
import TopicIcon from '@mui/icons-material/LabelImportant';
import QuestionnaireIcon from '@mui/icons-material/QuestionAnswer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { AnswerContext } from '../../context/AnswerContext';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const QuestionnaireView = () => {
  const localClasses = useStyles();
  const {
    selectedQuestionnaire,
    selectedCategory,
    categories,
    handleSelectCategory,
    handleSelectQuestionnaire,
    topics,
    handleSelectTopic,
    questionCountByCategory,
    questionCountByTopic,
    questionCountTotal,
  } = useContext(PollContext);
  const { answerCountTotal, answerCountByCategory, answerCountByTopic } =
    useContext(AnswerContext);

  const classes = defaultStyles();
  const fixedHeightHeader = clsx(classes.paper, classes.fixedHeightMedium);

  function toggleExpand(selectedQuestionnaire, category) {
    if (selectedCategory && category.id === selectedCategory.id) {
      handleSelectCategory(null);
    } else {
      handleSelectCategory(selectedQuestionnaire, category);
    }
  }

  const renderCategories = () => {
    return (
      <>
        {categories &&
          categories.map((category) => {
            const expanded =
              selectedCategory && category.id === selectedCategory.id;
            let answerCount = answerCountByCategory(category.id);
            let questionCount = questionCountByCategory(category.id);
            let answerProgress = (answerCount / questionCount) * 100.0;

            return (
              <Grid item xs={12} md={6} lg={4} key={category.id}>
                <Card className={classes.card}>
                  <CardContent
                    className={classes.fixedHeightSmall}
                    onClick={() =>
                      toggleExpand(selectedQuestionnaire, category)
                    }
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {answerCount} of {questionCount}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={answerProgress}
                    />
                    <Typography gutterBottom variant="h6" component="h2">
                      {category.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {category.description}
                    </Typography>
                  </CardContent>
                  <CardActions disableSpacing>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                      })}
                      onClick={() =>
                        toggleExpand(selectedQuestionnaire, category)
                      }
                      aria-expanded={expanded}
                      aria-label="show topics"
                      size="large"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </CardActions>
                  <Collapse in={expanded} unmountOnExit timeout={0}>
                    <List component="nav">
                      {expanded &&
                        topics &&
                        topics.map((topic) => {
                          const topicAnswerCount = answerCountByTopic(
                            category.id,
                            topic.id
                          );
                          const topicQuestionCount = questionCountByTopic(
                            category.id,
                            topic.id
                          );
                          const topicAnswerProgress =
                            (topicAnswerCount / topicQuestionCount) * 100.0;

                          return (
                            <ListItem
                              button
                              onClick={() =>
                                handleSelectTopic(selectedQuestionnaire, topic)
                              }
                              key={topic.id}
                            >
                              <ListItemIcon>
                                <TopicIcon />
                              </ListItemIcon>
                              <ListItemText
                                disableTypography={true}
                                primary={topic.title}
                                secondary={
                                  <LinearProgress
                                    variant="determinate"
                                    value={topicAnswerProgress}
                                  />
                                }
                              />
                            </ListItem>
                          );
                        })}
                    </List>
                  </Collapse>
                </Card>
              </Grid>
            );
          })}
      </>
    );
  };

  let answerCount = answerCountTotal();
  let questionCount = questionCountTotal();
  let answerProgress = (answerCount / questionCount) * 100.0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {selectedCategory ? (
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                href="#"
                onClick={() => handleSelectQuestionnaire(selectedQuestionnaire)}
                className={localClasses.link}
                underline="hover"
              >
                <QuestionnaireIcon className={localClasses.icon} />
                {selectedQuestionnaire.title}
              </Link>
              <Typography color="textPrimary" className={localClasses.link}>
                <CategoryIcon className={localClasses.icon} />
                {selectedCategory.title}
              </Typography>
            </Breadcrumbs>
          ) : (
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary" className={localClasses.link}>
                <QuestionnaireIcon className={localClasses.icon} />
                {selectedQuestionnaire.title}
              </Typography>
            </Breadcrumbs>
          )}
        </Paper>
      </Grid>
      {/* Title and Description */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper className={fixedHeightHeader}>
          <Title>
            <QuestionnaireIcon className={localClasses.icon} />
            {selectedQuestionnaire.title}
          </Title>
          <div>{selectedQuestionnaire.description}</div>
        </Paper>
      </Grid>
      {/* Status */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper className={fixedHeightHeader}>
          <React.Fragment>
            <Title>Status</Title>
            <Typography
              color="textSecondary"
              className={classes.depositContext}
            >
              {answerCount + ' of ' + questionCount}
            </Typography>
            <LinearProgress variant="determinate" value={answerProgress} />
          </React.Fragment>
        </Paper>
      </Grid>
      {/* Categories */}
      {renderCategories()}
    </Grid>
  );
};

export default QuestionnaireView;
