import React, { useContext } from 'react';

import QuestionnaireSelection from './QuestionnaireSelection';
import Divider from '@mui/material/Divider';
import SaveButton from './SaveButton';
import { PollContext } from '../../context/PollContext';

const Explorer = () => {
  const { selectedQuestionnaire, auditMode } = useContext(PollContext);

  return (
    <>
      <QuestionnaireSelection />

      {selectedQuestionnaire && !auditMode && (
        <>
          <Divider />

          <SaveButton />
        </>
      )}
      <Divider />
    </>
  );
};

export default Explorer;
