import { makeStyles } from '@mui/styles';
import { colorScheme } from './globals';

const drawerWidth = 300;

const questionStyles = makeStyles((theme) => ({
  questionGrid: {
    borderRightWidth: '1px',
    borderRightColor: theme.palette.divider,
    borderRightStyle: 'solid',
    paddingRight: 8,
  },
  answerStatus: {
    float: 'right',
  },
}));

const defaultStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontFamily:
      'Nunito,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: colorScheme.grey1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: colorScheme.grey1,
    overflowX: 'hidden',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'scroll',
    overflowX: 'auto',
    backgroundColor: '#fafafa',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minHeight: 'calc(100vh - 128px)',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  card: {},
  fixedHeight: {
    height: 160,
  },
  fixedHeightMedium: {
    height: 120,
  },
  fixedHeightSmall: {
    height: 100,
  },
  headerLogo: {
    height: '2em',
    width: 'auto',
    marginRight: 10,
  },
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  hiddenInput: {
    display: 'none',
  },
  fileList: {},
  errorMsg: {
    textAlign: 'right',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  footerLinks: {
    flexGrow: 1,
  },
  footerText: {
    fontSize: '14px !important',
    '& > span': {
      fontSize: '14px !important',
    },
  },
  footerLink: {
    width: 'auto',
    flexGrow: 0,
    display: 'inline-block',
    fontSize: '14px !important',
    '& > a': {
      color: colorScheme.grey1,
      textDecoration: 'none',
      '&:visited': {
        color: colorScheme.grey1,
      },
      '&:hover': {
        color: colorScheme.blue,
        textDecoration: 'underline',
      },
    },
  },
  mainNavigation: {
    color: colorScheme.grey7,
  },
  mainNavigationIcon: {
    fill: colorScheme.grey7,
  },
  mainNavigationItem: {
    padding: 0,
    '&:hover': {
      background: `${colorScheme.blue}`,
    },
  },
  mainNavigationItemActive: {
    background: `${colorScheme.blue} !important`,
    '&:hover': {
      background: `${colorScheme.blue} !important`,
    },
  },
  mainNavigationItemInactive: {
    padding: 0,
    '&:hover': {
      background: `${colorScheme.grey1} !important`,
    },
  },
  mainNavigationSaveButtonText: {
    color: `${colorScheme.grey7} !important`,
    '& > p': {
      color: `${colorScheme.grey7} !important`,
    },
  },
  mainNavigationSaveButtonSaveIcon: {
    fill: colorScheme.grey5,
    '&:hover': {
      color: colorScheme.grey7,
    },
  },
  mainNavigationSaveButtonCircularProgress: {
    fill: colorScheme.grey7,
    '&:hover': {
      color: colorScheme.grey7,
    },
  },
}));

export { defaultStyles, questionStyles };
