const configPerEnv = {
  prod: {
    REACT_APP_AUTH0_DOMAIN: 'techminers.eu.auth0.com',
    REACT_APP_AUTH0_CLIENT_ID: '73aSufpY9nOw8x6N9HyU3iAke5sPUNC2',
    REACT_APP_AUTH0_AUDIENCE: 'one/api',
    REACT_APP_AUTH0_LOGOUT_URL: 'https://poll.techminers.org/',

    REACT_APP_API_URL_PREFIX: 'https://backend.poll.techminers.org',
    REACT_APP_POLL_API_BASE_URL:
      'https://backend.poll.techminers.org/forms/api',
  },

  stage: {
    REACT_APP_AUTH0_DOMAIN: 'techminers-dev.eu.auth0.com',
    REACT_APP_AUTH0_CLIENT_ID: 'glnRMNZeqwEhk5PvwAIpPxejIjQz6XF1',
    REACT_APP_AUTH0_AUDIENCE: 'one/api',
    REACT_APP_AUTH0_LOGOUT_URL: 'https://poll.stage.techminers.org/',

    REACT_APP_API_URL_PREFIX: 'https://backend.poll.stage.techminers.org',
    REACT_APP_POLL_API_BASE_URL:
      'https://backend.poll.stage.techminers.org/forms/api',
  },

  stage_1: {
    REACT_APP_AUTH0_DOMAIN: 'techminers-dev.eu.auth0.com',
    REACT_APP_AUTH0_CLIENT_ID: 'glnRMNZeqwEhk5PvwAIpPxejIjQz6XF1',
    REACT_APP_AUTH0_AUDIENCE: 'one/api',
    REACT_APP_AUTH0_LOGOUT_URL: 'https://poll.stage-1.techminers.org/',

    REACT_APP_API_URL_PREFIX: 'https://backend.poll.stage-1.techminers.org',
    REACT_APP_POLL_API_BASE_URL:
      'https://backend.poll.stage-1.techminers.org/forms/api',
  },

  stage_2: {
    REACT_APP_AUTH0_DOMAIN: 'techminers-dev.eu.auth0.com',
    REACT_APP_AUTH0_CLIENT_ID: 'glnRMNZeqwEhk5PvwAIpPxejIjQz6XF1',
    REACT_APP_AUTH0_AUDIENCE: 'one/api',
    REACT_APP_AUTH0_LOGOUT_URL: 'https://poll.stage-2.techminers.org/',

    REACT_APP_API_URL_PREFIX: 'https://backend.poll.stage-2.techminers.org',
    REACT_APP_POLL_API_BASE_URL:
      'https://backend.poll.stage-2.techminers.org/forms/api',
  },

  dev: {
    REACT_APP_AUTH0_DOMAIN: 'techminers-dev.eu.auth0.com',
    REACT_APP_AUTH0_CLIENT_ID: 'glnRMNZeqwEhk5PvwAIpPxejIjQz6XF1',
    REACT_APP_AUTH0_AUDIENCE: 'one/api',
    REACT_APP_AUTH0_LOGOUT_URL: 'http://localhost:3001/',

    REACT_APP_API_URL_PREFIX: '',
    REACT_APP_POLL_API_BASE_URL: '/forms/api',
  },
};

export const getConfig = (name) => {
  // https://github.com/react-static/react-static/blob/master/docs/concepts.md#detecting-browser-and-node-environments

  let env;

  if (typeof document !== 'undefined') {
    // We are in a browser context
    switch (window.location.hostname) {
      case 'localhost':
        env = 'dev';
        break;
      case 'poll.stage.techminers.org':
        env = 'stage';
        break;
      case 'poll.stage-1.techminers.org':
        env = 'stage_1';
        break;
      case 'poll.stage-2.techminers.org':
        env = 'stage_2';
        break;
      default:
        env = 'prod';
    }
  } else {
    // We are in a node context
    throw new Error('Not running in browser, unable to detect environment.');
  }

  return configPerEnv[env][name];
};
