/* Generated by restful-react */

import React from 'react';
import {
  Get,
  GetProps,
  useGet,
  UseGetProps,
  Mutate,
  MutateProps,
  useMutate,
  UseMutateProps,
} from 'restful-react';
export interface Answer {
  id?: string;
  qresponse: string;
  question: string;
  body?: string;
}

export interface Export {
  id?: string;
  questionnaire?: string;
  categories?: string;
  topics?: string;
  questions?: string;
  qresponses?: string;
  qStats?: string;
  qResponseAnswers?: string;
  created?: string;
  updated?: string;
  completion_date?: string | null;
  author: number;
}

export interface QResponse {
  id?: string;
  created?: string;
  updated?: string;
  questionnaire: string;
  author: number;
  completion_date?: string | null;
}

export interface Category {
  id?: string;
  questionnaire: string;
  title: string;
  description?: string;
  order?: number;
}

export interface Topic {
  id?: string;
  category: string;
  title: string;
  description?: string;
  order?: number;
}

export interface Invitation {
  id?: string;
  questionnaire: string;
  user: number;
}

export interface Questionnaire {
  id?: string;
  title: string;
  description?: string;
  is_published: boolean;
  pub_date?: string | null;
}

export interface QuestionnaireStatsResponse {
  id: string;
  category_count: number;
  topic_count: number;
  question_count: number;
  categories: { [key: string]: any };
}

export interface Question {
  id?: string;
  topic: string;
  title?: string;
  question: string;
  type: 'yesNo' | 'text' | 'radio' | 'file';
  description?: string;
  payload?: string;
  order?: number;
}

export interface User {
  id?: number;
  /**
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   */
  username: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  last_login?: string | null;
  date_joined?: string;
}

export type AnswerRequestBody = Answer;

export type AnswersCreateProps = Omit<
  MutateProps<Answer, unknown, void, AnswerRequestBody, void>,
  'path' | 'verb'
>;

/**
 * Saves a new Answer.
 */
export const AnswersCreate = (props: AnswersCreateProps) => (
  <Mutate<Answer, unknown, void, AnswerRequestBody, void>
    verb="POST"
    path={`/answers`}
    {...props}
  />
);

export type UseAnswersCreateProps = Omit<
  UseMutateProps<Answer, unknown, void, AnswerRequestBody, void>,
  'path' | 'verb'
>;

/**
 * Saves a new Answer.
 */
export const useAnswersCreate = (props: UseAnswersCreateProps) =>
  useMutate<Answer, unknown, void, AnswerRequestBody, void>(
    'POST',
    `/answers`,
    props
  );

export interface AnswersReadPathParams {
  /**
   * A UUID string identifying this answer.
   */
  id: string;
}

export type AnswersReadProps = Omit<
  GetProps<Answer, unknown, void, AnswersReadPathParams>,
  'path'
> &
  AnswersReadPathParams;

/**
 * Provides an Answer's details
 */
export const AnswersRead = ({ id, ...props }: AnswersReadProps) => (
  <Get<Answer, unknown, void, AnswersReadPathParams>
    path={`/answers/${id}`}
    {...props}
  />
);

export type UseAnswersReadProps = Omit<
  UseGetProps<Answer, unknown, void, AnswersReadPathParams>,
  'path'
> &
  AnswersReadPathParams;

/**
 * Provides an Answer's details
 */
export const useAnswersRead = ({ id, ...props }: UseAnswersReadProps) =>
  useGet<Answer, unknown, void, AnswersReadPathParams>(
    (paramsInPath: AnswersReadPathParams) => `/answers/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );

export interface AnswersUpdatePathParams {
  /**
   * A UUID string identifying this answer.
   */
  id: string;
}

export type AnswersUpdateProps = Omit<
  MutateProps<
    Answer,
    unknown,
    void,
    AnswerRequestBody,
    AnswersUpdatePathParams
  >,
  'path' | 'verb'
> &
  AnswersUpdatePathParams;

/**
 * Updates an Answer
 */
export const AnswersUpdate = ({ id, ...props }: AnswersUpdateProps) => (
  <Mutate<Answer, unknown, void, AnswerRequestBody, AnswersUpdatePathParams>
    verb="PUT"
    path={`/answers/${id}`}
    {...props}
  />
);

export type UseAnswersUpdateProps = Omit<
  UseMutateProps<
    Answer,
    unknown,
    void,
    AnswerRequestBody,
    AnswersUpdatePathParams
  >,
  'path' | 'verb'
> &
  AnswersUpdatePathParams;

/**
 * Updates an Answer
 */
export const useAnswersUpdate = ({ id, ...props }: UseAnswersUpdateProps) =>
  useMutate<Answer, unknown, void, AnswerRequestBody, AnswersUpdatePathParams>(
    'PUT',
    (paramsInPath: AnswersUpdatePathParams) => `/answers/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );

export interface AnswersFilesCreatePathParams {
  id: string;
}

export type AnswersFilesCreateProps = Omit<
  MutateProps<void, unknown, void, void, AnswersFilesCreatePathParams>,
  'path' | 'verb'
> &
  AnswersFilesCreatePathParams;

/**
 * Creates a new File for given answer.
 */
export const AnswersFilesCreate = ({
  id,
  ...props
}: AnswersFilesCreateProps) => (
  <Mutate<void, unknown, void, void, AnswersFilesCreatePathParams>
    verb="POST"
    path={`/answers/${id}/files`}
    {...props}
  />
);

export type UseAnswersFilesCreateProps = Omit<
  UseMutateProps<void, unknown, void, void, AnswersFilesCreatePathParams>,
  'path' | 'verb'
> &
  AnswersFilesCreatePathParams;

/**
 * Creates a new File for given answer.
 */
export const useAnswersFilesCreate = ({
  id,
  ...props
}: UseAnswersFilesCreateProps) =>
  useMutate<void, unknown, void, void, AnswersFilesCreatePathParams>(
    'POST',
    (paramsInPath: AnswersFilesCreatePathParams) =>
      `/answers/${paramsInPath.id}/files`,
    { pathParams: { id }, ...props }
  );

export interface AnswersFilesReadPathParams {
  id: string;
  filename: string;
}

export type AnswersFilesReadProps = Omit<
  GetProps<void, unknown, void, AnswersFilesReadPathParams>,
  'path'
> &
  AnswersFilesReadPathParams;

/**
 * Get the file of of an answer.
 */
export const AnswersFilesRead = ({
  id,
  filename,
  ...props
}: AnswersFilesReadProps) => (
  <Get<void, unknown, void, AnswersFilesReadPathParams>
    path={`/answers/${id}/files/${filename}`}
    {...props}
  />
);

export type UseAnswersFilesReadProps = Omit<
  UseGetProps<void, unknown, void, AnswersFilesReadPathParams>,
  'path'
> &
  AnswersFilesReadPathParams;

/**
 * Get the file of of an answer.
 */
export const useAnswersFilesRead = ({
  id,
  filename,
  ...props
}: UseAnswersFilesReadProps) =>
  useGet<void, unknown, void, AnswersFilesReadPathParams>(
    (paramsInPath: AnswersFilesReadPathParams) =>
      `/answers/${paramsInPath.id}/files/${paramsInPath.filename}`,
    { pathParams: { id, filename }, ...props }
  );

export interface AuditExcelExportReadPathParams {
  id: string;
}

export type AuditExcelExportReadProps = Omit<
  GetProps<void, unknown, void, AuditExcelExportReadPathParams>,
  'path'
> &
  AuditExcelExportReadPathParams;

/**
 * Export answers to a Questionnaire as excel
 */
export const AuditExcelExportRead = ({
  id,
  ...props
}: AuditExcelExportReadProps) => (
  <Get<void, unknown, void, AuditExcelExportReadPathParams>
    path={`/audit/excel-export/${id}`}
    {...props}
  />
);

export type UseAuditExcelExportReadProps = Omit<
  UseGetProps<void, unknown, void, AuditExcelExportReadPathParams>,
  'path'
> &
  AuditExcelExportReadPathParams;

/**
 * Export answers to a Questionnaire as excel
 */
export const useAuditExcelExportRead = ({
  id,
  ...props
}: UseAuditExcelExportReadProps) =>
  useGet<void, unknown, void, AuditExcelExportReadPathParams>(
    (paramsInPath: AuditExcelExportReadPathParams) =>
      `/audit/excel-export/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );

export interface AuditJsonExportReadPathParams {
  id: string;
}

export type AuditJsonExportReadProps = Omit<
  GetProps<Export, unknown, void, AuditJsonExportReadPathParams>,
  'path'
> &
  AuditJsonExportReadPathParams;

/**
 * Dump whole Questionnaire as json
 */
export const AuditJsonExportRead = ({
  id,
  ...props
}: AuditJsonExportReadProps) => (
  <Get<Export, unknown, void, AuditJsonExportReadPathParams>
    path={`/audit/json-export/${id}`}
    {...props}
  />
);

export type UseAuditJsonExportReadProps = Omit<
  UseGetProps<Export, unknown, void, AuditJsonExportReadPathParams>,
  'path'
> &
  AuditJsonExportReadPathParams;

/**
 * Dump whole Questionnaire as json
 */
export const useAuditJsonExportRead = ({
  id,
  ...props
}: UseAuditJsonExportReadProps) =>
  useGet<Export, unknown, void, AuditJsonExportReadPathParams>(
    (paramsInPath: AuditJsonExportReadPathParams) =>
      `/audit/json-export/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );

export type AuditQresponsesListProps = Omit<
  GetProps<QResponse[], unknown, void, void>,
  'path'
>;

/**
 * List all QResponses. A QResponse represents a response of one author/user to one questionnaire and contains n answers.
 */
export const AuditQresponsesList = (props: AuditQresponsesListProps) => (
  <Get<QResponse[], unknown, void, void>
    path={`/audit/qresponses`}
    {...props}
  />
);

export type UseAuditQresponsesListProps = Omit<
  UseGetProps<QResponse[], unknown, void, void>,
  'path'
>;

/**
 * List all QResponses. A QResponse represents a response of one author/user to one questionnaire and contains n answers.
 */
export const useAuditQresponsesList = (props: UseAuditQresponsesListProps) =>
  useGet<QResponse[], unknown, void, void>(`/audit/qresponses`, props);

export interface AuditQresponsesReadPathParams {
  id: string;
}

export type AuditQresponsesReadProps = Omit<
  GetProps<QResponse, unknown, void, AuditQresponsesReadPathParams>,
  'path'
> &
  AuditQresponsesReadPathParams;

/**
 * Get details on one specific QResponse
 */
export const AuditQresponsesRead = ({
  id,
  ...props
}: AuditQresponsesReadProps) => (
  <Get<QResponse, unknown, void, AuditQresponsesReadPathParams>
    path={`/audit/qresponses/${id}`}
    {...props}
  />
);

export type UseAuditQresponsesReadProps = Omit<
  UseGetProps<QResponse, unknown, void, AuditQresponsesReadPathParams>,
  'path'
> &
  AuditQresponsesReadPathParams;

/**
 * Get details on one specific QResponse
 */
export const useAuditQresponsesRead = ({
  id,
  ...props
}: UseAuditQresponsesReadProps) =>
  useGet<QResponse, unknown, void, AuditQresponsesReadPathParams>(
    (paramsInPath: AuditQresponsesReadPathParams) =>
      `/audit/qresponses/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );

export interface CategoriesReadPathParams {
  /**
   * A UUID string identifying this category.
   */
  id: string;
}

export type CategoriesReadProps = Omit<
  GetProps<Category, unknown, void, CategoriesReadPathParams>,
  'path'
> &
  CategoriesReadPathParams;

/**
 * List all Categories.
 */
export const CategoriesRead = ({ id, ...props }: CategoriesReadProps) => (
  <Get<Category, unknown, void, CategoriesReadPathParams>
    path={`/categories/${id}`}
    {...props}
  />
);

export type UseCategoriesReadProps = Omit<
  UseGetProps<Category, unknown, void, CategoriesReadPathParams>,
  'path'
> &
  CategoriesReadPathParams;

/**
 * List all Categories.
 */
export const useCategoriesRead = ({ id, ...props }: UseCategoriesReadProps) =>
  useGet<Category, unknown, void, CategoriesReadPathParams>(
    (paramsInPath: CategoriesReadPathParams) =>
      `/categories/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );

export interface CategoriesTopicsListPathParams {
  id: string;
}

export type CategoriesTopicsListProps = Omit<
  GetProps<Topic[], unknown, void, CategoriesTopicsListPathParams>,
  'path'
> &
  CategoriesTopicsListPathParams;

/**
 * Get all Topics of a Category.
 */
export const CategoriesTopicsList = ({
  id,
  ...props
}: CategoriesTopicsListProps) => (
  <Get<Topic[], unknown, void, CategoriesTopicsListPathParams>
    path={`/categories/${id}/topics`}
    {...props}
  />
);

export type UseCategoriesTopicsListProps = Omit<
  UseGetProps<Topic[], unknown, void, CategoriesTopicsListPathParams>,
  'path'
> &
  CategoriesTopicsListPathParams;

/**
 * Get all Topics of a Category.
 */
export const useCategoriesTopicsList = ({
  id,
  ...props
}: UseCategoriesTopicsListProps) =>
  useGet<Topic[], unknown, void, CategoriesTopicsListPathParams>(
    (paramsInPath: CategoriesTopicsListPathParams) =>
      `/categories/${paramsInPath.id}/topics`,
    { pathParams: { id }, ...props }
  );

export type InvitationsListProps = Omit<
  GetProps<Invitation, unknown, void, void>,
  'path'
>;

/**
 * All Invitations of given user id
 */
export const InvitationsList = (props: InvitationsListProps) => (
  <Get<Invitation, unknown, void, void> path={`/invitations`} {...props} />
);

export type UseInvitationsListProps = Omit<
  UseGetProps<Invitation, unknown, void, void>,
  'path'
>;

/**
 * All Invitations of given user id
 */
export const useInvitationsList = (props: UseInvitationsListProps) =>
  useGet<Invitation, unknown, void, void>(`/invitations`, props);

export type PermissionsListProps = Omit<
  GetProps<void, unknown, void, void>,
  'path'
>;

/**
 * Get user permissions of the JWT token.
 */
export const PermissionsList = (props: PermissionsListProps) => (
  <Get<void, unknown, void, void> path={`/permissions`} {...props} />
);

export type UsePermissionsListProps = Omit<
  UseGetProps<void, unknown, void, void>,
  'path'
>;

/**
 * Get user permissions of the JWT token.
 */
export const usePermissionsList = (props: UsePermissionsListProps) =>
  useGet<void, unknown, void, void>(`/permissions`, props);

export type QresponsesCreateProps = Omit<
  MutateProps<QResponse, unknown, void, QResponse, void>,
  'path' | 'verb'
>;

export const QresponsesCreate = (props: QresponsesCreateProps) => (
  <Mutate<QResponse, unknown, void, QResponse, void>
    verb="POST"
    path={`/qresponses`}
    {...props}
  />
);

export type UseQresponsesCreateProps = Omit<
  UseMutateProps<QResponse, unknown, void, QResponse, void>,
  'path' | 'verb'
>;

export const useQresponsesCreate = (props: UseQresponsesCreateProps) =>
  useMutate<QResponse, unknown, void, QResponse, void>(
    'POST',
    `/qresponses`,
    props
  );

export interface QresponsesAnswersListPathParams {
  id: string;
}

export type QresponsesAnswersListProps = Omit<
  GetProps<Answer[], unknown, void, QresponsesAnswersListPathParams>,
  'path'
> &
  QresponsesAnswersListPathParams;

/**
 * List all Answers of the given QResponse.
 */
export const QresponsesAnswersList = ({
  id,
  ...props
}: QresponsesAnswersListProps) => (
  <Get<Answer[], unknown, void, QresponsesAnswersListPathParams>
    path={`/qresponses/${id}/answers`}
    {...props}
  />
);

export type UseQresponsesAnswersListProps = Omit<
  UseGetProps<Answer[], unknown, void, QresponsesAnswersListPathParams>,
  'path'
> &
  QresponsesAnswersListPathParams;

/**
 * List all Answers of the given QResponse.
 */
export const useQresponsesAnswersList = ({
  id,
  ...props
}: UseQresponsesAnswersListProps) =>
  useGet<Answer[], unknown, void, QresponsesAnswersListPathParams>(
    (paramsInPath: QresponsesAnswersListPathParams) =>
      `/qresponses/${paramsInPath.id}/answers`,
    { pathParams: { id }, ...props }
  );

export interface QuestionnairesReadPathParams {
  /**
   * A UUID string identifying this questionnaire.
   */
  id: string;
}

export type QuestionnairesReadProps = Omit<
  GetProps<Questionnaire, unknown, void, QuestionnairesReadPathParams>,
  'path'
> &
  QuestionnairesReadPathParams;

/**
 * All details of given Questionnaire.
 */
export const QuestionnairesRead = ({
  id,
  ...props
}: QuestionnairesReadProps) => (
  <Get<Questionnaire, unknown, void, QuestionnairesReadPathParams>
    path={`/questionnaires/${id}`}
    {...props}
  />
);

export type UseQuestionnairesReadProps = Omit<
  UseGetProps<Questionnaire, unknown, void, QuestionnairesReadPathParams>,
  'path'
> &
  QuestionnairesReadPathParams;

/**
 * All details of given Questionnaire.
 */
export const useQuestionnairesRead = ({
  id,
  ...props
}: UseQuestionnairesReadProps) =>
  useGet<Questionnaire, unknown, void, QuestionnairesReadPathParams>(
    (paramsInPath: QuestionnairesReadPathParams) =>
      `/questionnaires/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );

export interface QuestionnairesCategoriesListPathParams {
  id: string;
}

export type QuestionnairesCategoriesListProps = Omit<
  GetProps<Category[], unknown, void, QuestionnairesCategoriesListPathParams>,
  'path'
> &
  QuestionnairesCategoriesListPathParams;

/**
 * List all Categories of given Questionnaire.
 */
export const QuestionnairesCategoriesList = ({
  id,
  ...props
}: QuestionnairesCategoriesListProps) => (
  <Get<Category[], unknown, void, QuestionnairesCategoriesListPathParams>
    path={`/questionnaires/${id}/categories`}
    {...props}
  />
);

export type UseQuestionnairesCategoriesListProps = Omit<
  UseGetProps<
    Category[],
    unknown,
    void,
    QuestionnairesCategoriesListPathParams
  >,
  'path'
> &
  QuestionnairesCategoriesListPathParams;

/**
 * List all Categories of given Questionnaire.
 */
export const useQuestionnairesCategoriesList = ({
  id,
  ...props
}: UseQuestionnairesCategoriesListProps) =>
  useGet<Category[], unknown, void, QuestionnairesCategoriesListPathParams>(
    (paramsInPath: QuestionnairesCategoriesListPathParams) =>
      `/questionnaires/${paramsInPath.id}/categories`,
    { pathParams: { id }, ...props }
  );

export interface QuestionnairesQresponsesListPathParams {
  id: string;
}

export type QuestionnairesQresponsesListProps = Omit<
  GetProps<QResponse[], unknown, void, QuestionnairesQresponsesListPathParams>,
  'path'
> &
  QuestionnairesQresponsesListPathParams;

/**
 * List all QResponses of given Questionnaire.
 */
export const QuestionnairesQresponsesList = ({
  id,
  ...props
}: QuestionnairesQresponsesListProps) => (
  <Get<QResponse[], unknown, void, QuestionnairesQresponsesListPathParams>
    path={`/questionnaires/${id}/qresponses`}
    {...props}
  />
);

export type UseQuestionnairesQresponsesListProps = Omit<
  UseGetProps<
    QResponse[],
    unknown,
    void,
    QuestionnairesQresponsesListPathParams
  >,
  'path'
> &
  QuestionnairesQresponsesListPathParams;

/**
 * List all QResponses of given Questionnaire.
 */
export const useQuestionnairesQresponsesList = ({
  id,
  ...props
}: UseQuestionnairesQresponsesListProps) =>
  useGet<QResponse[], unknown, void, QuestionnairesQresponsesListPathParams>(
    (paramsInPath: QuestionnairesQresponsesListPathParams) =>
      `/questionnaires/${paramsInPath.id}/qresponses`,
    { pathParams: { id }, ...props }
  );

export interface QuestionnairesStatsListPathParams {
  id: string;
}

export type QuestionnairesStatsListProps = Omit<
  GetProps<
    QuestionnaireStatsResponse,
    unknown,
    void,
    QuestionnairesStatsListPathParams
  >,
  'path'
> &
  QuestionnairesStatsListPathParams;

/**
 * Give Stats of given Questionnaire.
 */
export const QuestionnairesStatsList = ({
  id,
  ...props
}: QuestionnairesStatsListProps) => (
  <Get<
    QuestionnaireStatsResponse,
    unknown,
    void,
    QuestionnairesStatsListPathParams
  >
    path={`/questionnaires/${id}/stats`}
    {...props}
  />
);

export type UseQuestionnairesStatsListProps = Omit<
  UseGetProps<
    QuestionnaireStatsResponse,
    unknown,
    void,
    QuestionnairesStatsListPathParams
  >,
  'path'
> &
  QuestionnairesStatsListPathParams;

/**
 * Give Stats of given Questionnaire.
 */
export const useQuestionnairesStatsList = ({
  id,
  ...props
}: UseQuestionnairesStatsListProps) =>
  useGet<
    QuestionnaireStatsResponse,
    unknown,
    void,
    QuestionnairesStatsListPathParams
  >(
    (paramsInPath: QuestionnairesStatsListPathParams) =>
      `/questionnaires/${paramsInPath.id}/stats`,
    { pathParams: { id }, ...props }
  );

export interface TopicsReadPathParams {
  /**
   * A UUID string identifying this topic.
   */
  id: string;
}

export type TopicsReadProps = Omit<
  GetProps<Topic, unknown, void, TopicsReadPathParams>,
  'path'
> &
  TopicsReadPathParams;

export const TopicsRead = ({ id, ...props }: TopicsReadProps) => (
  <Get<Topic, unknown, void, TopicsReadPathParams>
    path={`/topics/${id}`}
    {...props}
  />
);

export type UseTopicsReadProps = Omit<
  UseGetProps<Topic, unknown, void, TopicsReadPathParams>,
  'path'
> &
  TopicsReadPathParams;

export const useTopicsRead = ({ id, ...props }: UseTopicsReadProps) =>
  useGet<Topic, unknown, void, TopicsReadPathParams>(
    (paramsInPath: TopicsReadPathParams) => `/topics/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );

export interface TopicsQuestionsListPathParams {
  id: string;
}

export type TopicsQuestionsListProps = Omit<
  GetProps<Question[], unknown, void, TopicsQuestionsListPathParams>,
  'path'
> &
  TopicsQuestionsListPathParams;

/**
 * List all Questions of given Topic.
 */
export const TopicsQuestionsList = ({
  id,
  ...props
}: TopicsQuestionsListProps) => (
  <Get<Question[], unknown, void, TopicsQuestionsListPathParams>
    path={`/topics/${id}/questions`}
    {...props}
  />
);

export type UseTopicsQuestionsListProps = Omit<
  UseGetProps<Question[], unknown, void, TopicsQuestionsListPathParams>,
  'path'
> &
  TopicsQuestionsListPathParams;

/**
 * List all Questions of given Topic.
 */
export const useTopicsQuestionsList = ({
  id,
  ...props
}: UseTopicsQuestionsListProps) =>
  useGet<Question[], unknown, void, TopicsQuestionsListPathParams>(
    (paramsInPath: TopicsQuestionsListPathParams) =>
      `/topics/${paramsInPath.id}/questions`,
    { pathParams: { id }, ...props }
  );

export interface UsersReadPathParams {
  /**
   * A unique integer value identifying this user.
   */
  id: number;
}

export type UsersReadProps = Omit<
  GetProps<User, unknown, void, UsersReadPathParams>,
  'path'
> &
  UsersReadPathParams;

/**
 * All details of given User.
 */
export const UsersRead = ({ id, ...props }: UsersReadProps) => (
  <Get<User, unknown, void, UsersReadPathParams>
    path={`/users/${id}`}
    {...props}
  />
);

export type UseUsersReadProps = Omit<
  UseGetProps<User, unknown, void, UsersReadPathParams>,
  'path'
> &
  UsersReadPathParams;

/**
 * All details of given User.
 */
export const useUsersRead = ({ id, ...props }: UseUsersReadProps) =>
  useGet<User, unknown, void, UsersReadPathParams>(
    (paramsInPath: UsersReadPathParams) => `/users/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );
