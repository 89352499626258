import React, { useEffect, useState, useContext } from 'react';
import { PollContext } from '../../context/PollContext';
import { useAuth0 } from '@auth0/auth0-react';
import { MessageContext } from '../../context/MessageContext';
import { ConfigContext } from '../../context/ConfigContext';
import axios from 'axios';

const PollManagement = (props) => {
  const { handleError } = useContext(MessageContext);
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { apiUrlPrefix } = useContext(ConfigContext);

  // audit mode
  const [auditMode, setAuditMode] = useState(false);
  const [auditCandidates, setAuditCandidates] = useState();
  const [auditQResponseId, setAuditQResponseId] = useState();

  const loadAuditCandidates = async () => {
    const token = await getAccessTokenSilently();
    return axios
      .get(`${apiUrlPrefix}/forms/api/audit/qresponses`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const data = response.data;
        // eslint-disable-next-line no-console
        console.log('audit qresponses: ', data);
        setAuditCandidates(data);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  /**
   * resets all states that the user has influence on
   */
  function resetStateToInitial() {
    setCategories();
    setQStats();
    setQuestionnaires();
    setSelectedQuestionnaire();
    setSelectedTopic();
    setSelectedCategory();
  }

  const activateAuditMode = async () => {
    loadAuditCandidates();
    resetStateToInitial();
    setAuditMode(true);
  };

  const startAudit = async (qResponseId, questionnaireId) => {
    resetStateToInitial();
    setAuditQResponseId(qResponseId);
    loadQuestionnaires([questionnaireId]);
  };

  const [permissions, setPermissions] = useState(undefined);

  const loadPermissions = async () => {
    const token = await getAccessTokenSilently();

    return axios
      .get(`${apiUrlPrefix}/forms/api/permissions`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const data = response.data;

        setPermissions(data);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      loadPermissions();
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated]);

  // Questionnaire selection
  const [questionnaires, setQuestionnaires] = useState();

  useEffect(() => {
    if (permissions && permissions.includes('poll:read:questionnaires')) {
      loadInvitations();
    }
    // eslint-disable-next-line
  }, [permissions]);

  const loadInvitations = async () => {
    const token = await getAccessTokenSilently();
    return axios
      .get(`${apiUrlPrefix}/forms/api/invitations`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const data = response.data;
        // eslint-disable-next-line no-console
        console.log('invitation data: ', data);
        loadQuestionnaires(data.map((invite) => invite.questionnaire));
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const loadQuestionnaires = async (questionnaireIds) => {
    const token = await getAccessTokenSilently();

    let fetchers = questionnaireIds.map((id) => {
      return axios.get(`${apiUrlPrefix}/forms/api/questionnaires/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    });

    return Promise.all(fetchers)
      .then((responseList) => {
        const dataList = responseList.map((response) => response.data);
        setQuestionnaires(dataList);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const [categories, setCategories] = useState();
  const loadCategories = async (questionnaireId) => {
    const token = await getAccessTokenSilently();

    axios
      .get(
        `${apiUrlPrefix}/forms/api/questionnaires/${questionnaireId}/categories`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        const data = response.data;
        setCategories(data);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const [qStats, setQStats] = useState();
  const loadQuestionnaireStats = async (questionnaireId) => {
    const token = await getAccessTokenSilently();

    axios
      .get(
        `${apiUrlPrefix}/forms/api/questionnaires/${questionnaireId}/stats`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        const data = response.data;
        setQStats(data);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  // Questionnaire state
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState();
  const handleSelectQuestionnaire = async (questionnaire) => {
    if (
      (!selectedQuestionnaire && questionnaire) ||
      questionnaire.id !== selectedQuestionnaire.id
    ) {
      setCategories();
      setQStats();
      setSelectedQuestionnaire(questionnaire);
      loadCategories(questionnaire.id);
      loadQuestionnaireStats(questionnaire.id);
    }
    setSelectedCategory();
    setSelectedTopic();
  };

  // Category state
  const [selectedCategory, setSelectedCategory] = useState();
  const handleSelectCategory = async (questionnaire, category) => {
    setSelectedTopic();

    if (!category) {
      setTopics([]);
      setSelectedCategory();
    } else if (!selectedCategory || category.id !== selectedCategory.id) {
      setTopics([]);
      setSelectedCategory(category);
      loadTopics(questionnaire.id, category.id);
    }
  };

  // Topic state
  const [topics, setTopics] = useState([]);
  const loadTopics = async (questionnaireId, categoryId) => {
    const token = await getAccessTokenSilently();

    // load the category's topics into the state
    axios
      .get(
        `${apiUrlPrefix}/forms/api/questionnaires/${questionnaireId}/category/${categoryId}/topics`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const data = response.data;
        setTopics(data);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const [selectedTopic, setSelectedTopic] = useState();
  const [prevTopic, setPrevTopic] = useState();
  const [nextTopic, setNextTopic] = useState();

  const handleSelectTopic = (questionnaire, topic) => {
    if ((!selectedTopic && topic) || topic.id !== selectedTopic.id) {
      setQuestions([]);
      setSelectedTopic(topic);
      setPrevTopic(getPrevTopic(topic));
      setNextTopic(getNextTopic(topic));
      loadQuestions(questionnaire.id, topic.id);
    }
  };

  function getPrevTopic(topic) {
    const topicPos = topics.indexOf(topic);
    if (topicPos >= 1) {
      return topics[topicPos - 1];
    } else {
      return null;
    }
  }

  function getNextTopic(topic) {
    const topicPos = topics.indexOf(topic);
    if (topicPos < topics.length - 1) {
      return topics[topicPos + 1];
    } else {
      return null;
    }
  }

  // Question state
  const [questions, setQuestions] = useState([]);
  const loadQuestions = async (questionnaireId, topicId) => {
    const token = await getAccessTokenSilently();

    axios
      .get(
        `${apiUrlPrefix}/forms/api/questionnaires/${questionnaireId}/topic/${topicId}/questions`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const data = response.data;
        // eslint-disable-next-line no-console
        console.log('data: ', data);
        let questions = data.map((questionData) => {
          return {
            id: questionData.id,
            topicId: questionData.topic,
            type: questionData.type,
            title: questionData.title,
            question: questionData.question,
            description: questionData.description,
            payload: questionData.payload
              ? JSON.parse(questionData.payload)
              : null,
            order: questionData.order,
          };
        });
        setQuestions(questions);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const questionCountTotal = () => {
    if (qStats) {
      return qStats['question_count'];
    } else {
      return 0;
    }
  };

  const questionCountByCategory = (categoryId) => {
    if (qStats) {
      return qStats['categories'][categoryId]['question_count'];
    } else {
      return 0;
    }
  };

  const questionCountByTopic = (categoryId, topicId) => {
    if (qStats) {
      return qStats['categories'][categoryId]['topics'][topicId][
        'question_count'
      ];
    } else {
      return 0;
    }
  };

  return (
    <PollContext.Provider
      value={{
        questionnaires,
        selectedQuestionnaire,
        qStats,
        handleSelectQuestionnaire,
        categories,
        selectedCategory,
        handleSelectCategory,
        topics,
        selectedTopic,
        prevTopic,
        nextTopic,
        handleSelectTopic,
        questions,
        questionCountTotal: questionCountTotal,
        questionCountByCategory,
        questionCountByTopic,
        activateAuditMode,
        auditMode,
        auditCandidates,
        startAudit,
        auditQResponseId,
      }}
    >
      {props.children}
    </PollContext.Provider>
  );
};

export default PollManagement;
