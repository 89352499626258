import React, { useContext } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { defaultStyles } from '../../layout/styleProvider';

import { PollContext } from '../../context/PollContext';
import { Button } from '@mui/material';
import { colorScheme } from '../../layout/globals';
import AnswerContext from '../../context/AnswerContext';

const QuestionnaireSelection = () => {
  const { questionnaires, handleSelectQuestionnaire, selectedQuestionnaire } =
    useContext(PollContext);
  const { answerPersistenceState } = useContext(AnswerContext);
  const classes = defaultStyles();

  return (
    <List className={classes.mainNavigation}>
      <ListSubheader
        inset
        sx={{ color: '#fff', backgroundColor: colorScheme.grey1 }}
      >
        Questionnaires
      </ListSubheader>

      {questionnaires &&
        questionnaires.map((quest) => {
          const isSelected =
            selectedQuestionnaire && quest.id === selectedQuestionnaire.id;

          return (
            <ListItem
              component="li"
              key={quest.id}
              className={`${classes.mainNavigationItem} ${
                isSelected && classes.mainNavigationItemActive
              }`}
              sx={{
                '& .MuiButtonBase-root': { padding: '8px 16px' },
                ':hover':
                  answerPersistenceState === 'saving'
                    ? { background: 'none !important' }
                    : {},
              }}
            >
              <Button
                data-testid={quest.id}
                title={quest.title}
                selected={isSelected}
                onClick={() => handleSelectQuestionnaire(quest)}
                sx={{
                  color: '#fff',
                  textTransform: 'none',
                  minWidth: 'unset',
                  ':disabled': {
                    color: '#fff',
                    opacity: 0.7,
                  },
                }}
                disabled={answerPersistenceState === 'saving'}
              >
                <ListItemIcon>
                  <QuestionAnswerIcon className={classes.mainNavigationIcon} />
                </ListItemIcon>

                <ListItemText primary={quest.title} />
              </Button>
            </ListItem>
          );
        })}
    </List>
  );
};

export default QuestionnaireSelection;
