import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { defaultStyles } from '../../layout/styleProvider';
import { PollContext } from '../../context/PollContext';
import { AnswerContext } from '../../context/AnswerContext';
import { Box, CircularProgress, Stack } from '@mui/material';
import { Questionnaire, Topic } from '../../../api-client/poll-api';

type TSubmitTarget = {
  questionnaire?: Questionnaire;
  topic?: Topic;
};

const TopicPagination = () => {
  const {
    prevTopic,
    nextTopic,
    handleSelectTopic,
    handleSelectQuestionnaire,
    selectedQuestionnaire,
  }: any = useContext(PollContext);
  const { handleSaveAnswers, answerPersistenceState } =
    useContext(AnswerContext);
  const [submittedTarget, setSubmittedTarget] = useState<
    undefined | TSubmitTarget
  >(undefined);

  const classes = defaultStyles();

  const submitAnswers = (target: TSubmitTarget) => {
    window.onbeforeunload = () => true;
    handleSaveAnswers();
    setSubmittedTarget(target);
  };

  useEffect(() => {
    if (submittedTarget && answerPersistenceState === 'saved') {
      window.onbeforeunload = () => undefined;
      if (submittedTarget.topic) {
        handleSelectTopic(submittedTarget.questionnaire, submittedTarget.topic);
      } else if (submittedTarget.questionnaire) {
        handleSelectQuestionnaire(submittedTarget.questionnaire);
      }
    }
  }, [submittedTarget, answerPersistenceState]);

  return (
    <Stack direction={'row'} alignItems={'center'}>
      {prevTopic && (
        <Button
          variant="contained"
          className={classes.button}
          disabled={answerPersistenceState === 'saving'}
          onClick={() =>
            submitAnswers({
              questionnaire: selectedQuestionnaire,
              topic: prevTopic,
            })
          }
        >
          Back
        </Button>
      )}
      {nextTopic ? (
        <Button
          variant="contained"
          className={classes.button}
          disabled={answerPersistenceState === 'saving'}
          onClick={() =>
            submitAnswers({
              questionnaire: selectedQuestionnaire,
              topic: nextTopic,
            })
          }
        >
          Next
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={answerPersistenceState === 'saving'}
          onClick={() =>
            submitAnswers({ questionnaire: selectedQuestionnaire })
          }
        >
          Done
        </Button>
      )}
      {answerPersistenceState === 'saving' && (
        <Box>
          <CircularProgress size={16} sx={{ ml: 2, mr: 1 }} />
          Saving changes...
        </Box>
      )}
    </Stack>
  );
};

export default TopicPagination;
