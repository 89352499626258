import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Question from '../questions/Question';
import TopicPagination from './TopicPagination';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Title from '../dashboard/Title';
import { defaultStyles } from '../../layout/styleProvider';
import { AnswerContext } from '../../context/AnswerContext';
import { PollContext } from '../../context/PollContext';
import TopicIcon from '@mui/icons-material/LabelImportant';
import QuestionnaireIcon from '@mui/icons-material/QuestionAnswer';
import CategoryIcon from '@mui/icons-material/Assignment';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const TopicView = () => {
  const localClasses = useStyles();
  const { answers, answerCountByTopic } = useContext(AnswerContext);
  const {
    selectedQuestionnaire,
    selectedTopic,
    selectedCategory,
    handleSelectCategory,
    handleSelectQuestionnaire,
    questions,
    questionCountByTopic,
  } = useContext(PollContext);

  const classes = defaultStyles();
  const fixedHeightHeader = clsx(classes.paper, classes.fixedHeightMedium);

  const renderQuestions = () => {
    return (
      <>
        {questions.map((question) => {
          return (
            <Grid item xs={12} key={question.id}>
              <Paper className={classes.paper}>
                <Question
                  key={question.id}
                  question={question}
                  answer={answers ? answers[question.id] : null}
                />
              </Paper>
            </Grid>
          );
        })}
      </>
    );
  };

  let answerCount = answerCountByTopic(selectedCategory.id, selectedTopic.id);
  let questionCount = questionCountByTopic(
    selectedCategory.id,
    selectedTopic.id
  );
  let answerProgress = (answerCount / questionCount) * 100.0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="#"
              onClick={() => handleSelectQuestionnaire(selectedQuestionnaire)}
              className={localClasses.link}
              underline="hover"
            >
              <QuestionnaireIcon className={localClasses.icon} />
              {selectedQuestionnaire.title}
            </Link>
            <Link
              color="inherit"
              href="#"
              onClick={() => handleSelectCategory(selectedCategory)}
              className={localClasses.link}
              underline="hover"
            >
              <CategoryIcon className={localClasses.icon} />
              {selectedCategory.title}
            </Link>
            <Typography color="textPrimary" className={localClasses.link}>
              <TopicIcon className={localClasses.icon} />
              {selectedTopic.title}
            </Typography>
          </Breadcrumbs>
        </Paper>
      </Grid>
      {/* Title and Description */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper className={fixedHeightHeader}>
          <Title>
            <TopicIcon className={localClasses.icon} />
            {selectedTopic.title}
          </Title>
          <div>{selectedTopic.description}</div>
        </Paper>
      </Grid>
      {/* Status */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper className={fixedHeightHeader}>
          <React.Fragment>
            <Title>Status</Title>
            <Typography
              color="textSecondary"
              className={classes.depositContext}
            >
              {answerCount + ' of ' + questionCount}
            </Typography>
            <LinearProgress variant="determinate" value={answerProgress} />
          </React.Fragment>
        </Paper>
      </Grid>
      {/* Topics */}
      {questions && questions.length ? (
        <>
          {renderQuestions()}
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <TopicPagination />
            </Paper>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <CircularProgress />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default TopicView;
