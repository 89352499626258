import React from 'react';

export const PollContext = React.createContext({
  questionnaires: [],
  selectedQuestionnaire: undefined,
  handleSelectQuestionnaire: () => {},
  qStats: undefined,
  categories: [],
  selectedCategory: undefined,
  handleSelectCategory: () => {},
  topics: [],
  selectedTopic: undefined,
  prevTopic: undefined,
  nextTopic: undefined,
  handleSelectTopic: () => {},
  questions: [],
  auditMode: false,
  auditQResponseId: undefined,
  startAudit: () => {},
  activateAuditMode: () => {},
  questionCountTotal: () => {},
  questionCountByCategory: () => {},
  questionCountByTopic: () => {},
});

export default PollContext;
