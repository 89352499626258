import React, { useEffect, useState, useContext } from 'react';
import { AnswerContext } from '../../context/AnswerContext';
import { fetchJsonWithAuth } from '../../../utils/requestUtils';
import { useAuth0 } from '@auth0/auth0-react';
import { PollContext } from '../../context/PollContext';
import { MessageContext } from '../../context/MessageContext';
import { ConfigContext } from '../../context/ConfigContext';
import axios from 'axios';

const AnswerManagement = (props) => {
  const { selectedQuestionnaire, qStats, auditMode, auditQResponseId } =
    useContext(PollContext);
  const { handleError } = useContext(MessageContext);
  const { getAccessTokenSilently } = useAuth0();
  const { apiUrlPrefix } = useContext(ConfigContext);

  const [answerPersistenceState, setAnswerPersistenceState] = useState('saved');

  useEffect(() => {
    // reset all states
    setQResponse();
    setAnswers({});
    setAnswerPersistenceState('saved');

    if (selectedQuestionnaire) {
      loadQResponse(selectedQuestionnaire.id);
    }
    // eslint-disable-next-line
  }, [selectedQuestionnaire]);

  /********************************************/
  /* QResponse handling */
  /********************************************/
  const [qResponse, setQResponse] = useState();
  const loadQResponse = async (questionnaireId) => {
    return getAccessTokenSilently()
      .then((token) => {
        if (auditMode) {
          return axios.get(
            `${apiUrlPrefix}/forms/api/audit/qresponses/${auditQResponseId}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
        } else {
          return axios.get(
            `${apiUrlPrefix}/forms/api/questionnaires/${questionnaireId}/qresponses`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
        }
      })
      .then((response) => {
        //console.log("response", response)
        const data = response.data;

        if ('id' in data) {
          setQResponse(data);
          loadAnswers(data.id);
        } else if (data.length > 1) {
          throw new Error('got more than one qresponse');
        } else if (data.length === 1) {
          let qResponse = data[0];
          setQResponse(qResponse);
          loadAnswers(qResponse.id);
        } else {
          // no qresponse available
          // eslint-disable-next-line no-console
          console.log('no qresponse available');
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const createQResponse = (questionnaireId) => {
    // POST qresponse to backend
    let request_url = `${apiUrlPrefix}/forms/api/qresponses`;
    let request_method = 'POST';
    let request_data = {
      questionnaire: questionnaireId,
    };

    return fetchJsonWithAuth(
      getAccessTokenSilently,
      request_url,
      request_method,
      request_data
    )
      .then((data) => {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(data));
        setQResponse(data);
        return data;
      })
      .catch((error) => {
        handleError(error);
      });
  };

  /********************************************/
  /* Answer handling */
  /********************************************/
  const [answers, setAnswers] = useState({});
  const loadAnswers = async (qResponseId) => {
    const token = await getAccessTokenSilently();

    return axios
      .get(`${apiUrlPrefix}/forms/api/qresponses/${qResponseId}/answers`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        //console.log("response", response)
        const data = response.data;

        let newAnswers = {};
        for (let i = 0; i < data.length; i++) {
          let answer = data[i];
          let questionId = answer.question;
          newAnswers[questionId] = {
            id: answer.id,
            questionId: answer.question,
            body: JSON.parse(answer.body),
            hasChanged: false,
          };
        }
        setAnswers(newAnswers);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleAnswerChange = (
    type,
    questionId,
    newBody,
    afterSaveHook = null
  ) => {
    //console.debug("got answer change:", type, questionId, newBody)

    if (auditMode) {
      handleError('modifications during audit not allowed');
      return;
    }

    if (answerPersistenceState === 'saving') {
      return;
    }

    let newAnswers = Object.assign({}, answers);
    newAnswers[questionId] = {
      id: answers[questionId] ? answers[questionId].id : null,
      questionId: questionId,
      body: newBody,
      hasChanged: true,
      afterSaveHook: afterSaveHook,
    };

    setAnswerPersistenceState('unsaved');
    setAnswers(newAnswers);
  };

  const handleSaveAnswers = async () => {
    setAnswerPersistenceState('saving');

    let targetQResponse = qResponse
      ? qResponse
      : await createQResponse(selectedQuestionnaire.id);

    let newAnswers = Object.assign({}, answers);

    try {
      for (const [questionId, answer] of Object.entries(newAnswers)) {
        if (answer.hasChanged) {
          //console.log('saving answer for question: ', questionId, answer);

          let answerBodyWithoutFiles = JSON.parse(JSON.stringify(answer.body));

          if ('files' in answerBodyWithoutFiles) {
            answerBodyWithoutFiles.files = [];
          }

          let answerId = await saveOrUpdateAnswer(
            targetQResponse.id,
            questionId,
            answer,
            answerBodyWithoutFiles
          );
          if (!answerId) {
            throw new Error(`saving answer for question ${questionId} failed`);
          }

          answer.id = answer.id ? answer.id : answerId;

          if (answer.afterSaveHook) {
            await answer.afterSaveHook(answer);
            answer.afterSaveHook = null;
          }

          // update files list in DB if uploading to S3 is successful
          if ('files' in answer.body) {
            await saveOrUpdateAnswer(
              targetQResponse.id,
              questionId,
              answer,
              answer.body
            );
          }

          // everything done
          answer.hasChanged = false;
        }
      }
      setAnswerPersistenceState('saved');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setAnswerPersistenceState('unsaved');
    } finally {
      setAnswers(newAnswers);
    }
  };

  const saveOrUpdateAnswer = (
    targetQResponseId,
    questionId,
    answer,
    bodyToSave
  ) => {
    //console.log(`saving answer: targetQResponseId: ${targetQResponseId}, questionId: ${questionId}, answer:`, answer)

    let request_data = {
      qresponse: targetQResponseId,
      question: questionId,
      body: JSON.stringify(bodyToSave),
    };
    let request_url = null;
    let request_method = null;

    if (answer.id) {
      request_data.id = answer.id;
      request_url = `${apiUrlPrefix}/forms/api/answers/${answer.id}`;
      request_method = 'PUT';
    } else {
      request_url = `${apiUrlPrefix}/forms/api/answers`;
      request_method = 'POST';
    }

    // create/update answer
    return fetchJsonWithAuth(
      getAccessTokenSilently,
      request_url,
      request_method,
      request_data
    )
      .then((data) => {
        return data.id;
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const answerCountTotal = () => {
    return answers ? Object.keys(answers).length : 0;
  };

  const answerCountUnsaved = () => {
    let unsavedCount = 0;
    if (answers) {
      for (const answer of Object.values(answers)) {
        if (answer.hasChanged) unsavedCount++;
      }
    }

    return unsavedCount;
  };

  const answerCountByCategory = (categoryId) => {
    if (qStats && answers) {
      let questionIds = [];
      for (const topic of Object.values(
        qStats['categories'][categoryId]['topics']
      )) {
        questionIds = questionIds.concat(Object.keys(topic['questions']));
      }
      return questionIds.filter((questionId) =>
        Object.keys(answers).includes(questionId)
      ).length;
    } else {
      return 0;
    }
  };

  const answerCountByTopic = (categoryId, topicId) => {
    if (qStats && answers) {
      // eslint-disable-next-line no-console
      console.log('categoryId', categoryId);
      // eslint-disable-next-line no-console
      console.log('topicId', topicId);
      // eslint-disable-next-line no-console
      console.log(
        'qStats["categories"][categoryId]["topics"]',
        qStats['categories'][categoryId]['topics']
      );
      let questionIds = Object.keys(
        qStats['categories'][categoryId]['topics'][topicId]['questions']
      );
      return questionIds.filter((questionId) =>
        Object.keys(answers).includes(questionId)
      ).length;
    } else {
      return 0;
    }
  };

  return (
    <AnswerContext.Provider
      value={{
        answers,
        answerPersistenceState,
        handleSaveAnswers,
        handleAnswerChange,
        loadQResponse,
        answerCountTotal,
        answerCountByCategory,
        answerCountByTopic,
        answerCountUnsaved,
      }}
    >
      {props.children}
    </AnswerContext.Provider>
  );
};

export default AnswerManagement;
