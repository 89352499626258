import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Title from '../dashboard/Title';
import { defaultStyles } from '../../layout/styleProvider';
import { PollContext } from '../../context/PollContext';
import QuestionnaireIcon from '@mui/icons-material/QuestionAnswer';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Tooltip from '@mui/material/Tooltip';

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  useQuestionnairesRead,
  useUsersRead,
} from '../../../api-client/poll-api';
import { dateStringToYMD } from '../../../utils/dateFormat';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const AuditCandidate = ({ qresponse }) => {
  const { startAudit } = useContext(PollContext);

  const { data: questionnaire } = useQuestionnairesRead({
    id: qresponse.questionnaire,
  });
  const { data: author } = useUsersRead({ id: qresponse.author });

  const classes = defaultStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {questionnaire && questionnaire.title
              ? questionnaire.title.substring(0, 1)
              : '?'}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertIcon />
          </IconButton>
        }
        title={questionnaire && questionnaire.title}
        subheader={`Answers start ${dateStringToYMD(qresponse.created)}`}
      />
      <CardContent className={classes.fixedHeightSmall}>
        <Tooltip title={`username: ${author && author.username}`}>
          <div>
            <Typography variant="subtitle2">Author</Typography>
            <Typography>
              {author ? `${author.first_name} ${author.last_name}` : 'not set'}
            </Typography>
            <Typography variant="subtitle2">Email</Typography>
            <Typography>{author ? author.email : 'not set'}</Typography>
          </div>
        </Tooltip>
      </CardContent>
      <CardActions disableSpacing>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={() => startAudit(qresponse.id, qresponse.questionnaire)}
        >
          Start Audit
        </Button>
      </CardActions>
    </Card>
  );
};

const AuditView = () => {
  const { auditCandidates } = useContext(PollContext);

  const classes = defaultStyles();
  const localClasses = useStyles();

  const fixedHeightHeader = clsx(classes.paper, classes.fixedHeightMedium);

  const renderCandidates = () => {
    return (
      <>
        {auditCandidates &&
          auditCandidates.reverse().map((qresponse) => {
            return (
              <Grid item xs={12} md={6} lg={4} key={qresponse.id}>
                <AuditCandidate qresponse={qresponse} />
              </Grid>
            );
          })}
      </>
    );
  };

  return (
    <Grid container spacing={2}>
      {/* Title and Description */}
      <Grid item xs={12}>
        <Paper className={fixedHeightHeader}>
          <Title>
            <QuestionnaireIcon className={localClasses.icon} />
            Audit View
          </Title>
        </Paper>
      </Grid>
      {/* Candidates */}
      {renderCandidates()}
    </Grid>
  );
};

export default AuditView;
