import React from 'react';

export const AnswerContext = React.createContext({
  answers: {},
  answerPersistenceState: '',
  handleSaveAnswers: () => {},
  handleAnswerChange: () => {},
  loadQResponse: () => {},
  answerCountTotal: () => {},
  answerCountByCategory: () => {},
  answerCountByTopic: () => {},
  answerCountUnsaved: () => {},
});

export default AnswerContext;
