export const dateStringToYMD = (dateStr) => {
  try {
    const date = new Date(dateStr);
    return dateToYMD(date);
  } catch (error) {
    return 'n/a';
  }
};

export const dateToYMD = (date) => {
  return `${date.getFullYear()}-${zeroPad(date.getMonth() + 1)}-${zeroPad(
    date.getDay()
  )}`;
};

export const dateStringToYMDHM = (dateStr) => {
  try {
    const date = new Date(dateStr);
    return dateToYMDHM(date);
  } catch (error) {
    return 'n/a';
  }
};

export const dateToYMDHM = (date) => {
  return `${dateToYMD(date)} ${zeroPad(date.getHours())}:${zeroPad(
    date.getMinutes()
  )}`;
};

const zeroPad = (num, places = 2) => String(num).padStart(places, '0');
