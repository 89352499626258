import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import Divider from '@mui/material/Divider';

import { useAuth0 } from '@auth0/auth0-react';

import AnswerFileLink from './AnswerFileLink';

import { QuestionTypes } from './Question';
import { sendMultipartWithAuth } from '../../../utils/requestUtils';
import { defaultStyles } from '../../layout/styleProvider';
import { MessageContext } from '../../context/MessageContext';
import { AnswerContext } from '../../context/AnswerContext';
import { ConfigContext } from '../../context/ConfigContext';
import TextField from '@mui/material/TextField';
import { normalizeFileName } from '../../../utils/fileUtils';

const FileType = (props) => {
  const { handleError } = useContext(MessageContext);
  const { handleAnswerChange } = useContext(AnswerContext);
  const { apiUrlPrefix } = useContext(ConfigContext);

  const classes = defaultStyles();

  const { getAccessTokenSilently } = useAuth0();
  const fileInput = React.createRef();

  const uploadFiles = (newFilesToUpload, answerId) => {
    // eslint-disable-next-line no-console
    console.log('uploading files:', newFilesToUpload, answerId);
    window.onbeforeunload = () => true;

    let fetchers = newFilesToUpload.map((file) =>
      getUploadFetcher(file, answerId)
    );
    return Promise.all(fetchers).then(() => {
      // eslint-disable-next-line no-console
      console.log('done uploading.');
      window.onbeforeunload = undefined;
      setFilesToUpload([]);
    });
  };

  function getUploadFetcher(file, answerId) {
    // POST qresponse to backend
    let request_url = `${apiUrlPrefix}/forms/api/answers/${answerId}/files`;

    return sendMultipartWithAuth(
      getAccessTokenSilently,
      request_url,
      'POST',
      file
    )
      .then((data) => {
        // eslint-disable-next-line no-console
        console.log('file upload response:', JSON.stringify(data));
      })
      .catch((error) => {
        handleError(error);
        throw error;
      });
  }

  const [filesToUpload, setFilesToUpload] = useState([]);
  const addSelectedFiles = () => {
    // get files the user selected for upload
    let selectedFiles = [...fileInput.current.files];
    selectedFiles = selectedFiles.map((file) => normalizeFileName(file));
    let selectedFileNames = selectedFiles.map((file) => file.name);

    // cleanup: remove new files from to-upload and all files list
    let existingFilesMinusSelected = getFilesFromAnswer().filter(
      (file) => !selectedFileNames.includes(file.name)
    );
    let filesToUploadMinusSelected = filesToUpload.filter(
      (file) => !selectedFileNames.includes(file.name)
    );

    // update state of to-upload files
    let newFilesToUpload = filesToUploadMinusSelected.concat(selectedFiles);
    setFilesToUpload(newFilesToUpload);

    // update parent state of answer body
    handleAnswerChange(
      QuestionTypes.FILE,
      props.question.id,
      getAnswerBodyFilesChanged(
        existingFilesMinusSelected.concat(selectedFiles)
      ),
      (answer) => uploadFiles(newFilesToUpload, answer.id)
    );
  };

  function removeFile(fileName) {
    // remove file from to upload list
    setFilesToUpload(filesToUpload.filter((file) => file.name !== fileName));

    // remove file from answer body
    let answerFiles = getFilesFromAnswer().filter(
      (file) => file.name !== fileName
    );
    handleAnswerChange(
      QuestionTypes.FILE,
      props.question.id,
      getAnswerBodyFilesChanged(answerFiles)
    );
  }

  function getFilesFromAnswer() {
    return props.answer ? props.answer.body.files : [];
  }

  function getTextFromAnswer() {
    return props.answer ? props.answer.body.text : '';
  }

  function getAnswerBodyFilesChanged(allFiles) {
    // eslint-disable-next-line no-console
    console.debug('allFiles:', allFiles);

    return {
      text: getTextFromAnswer(),
      files: allFiles.map((file) => {
        return {
          name: file.name,
          size: file.size,
        };
      }),
    };
  }

  function getAnswerBodyTextChanged(value) {
    return {
      text: value,
      files: getFilesFromAnswer(),
    };
  }

  function renderFileLink(file) {
    let linkContent = <>{file.name}</>;

    if (filesToUpload.map((file) => file.name).includes(file.name)) {
      // file is not uploaded yet
      return <>{linkContent}</>;
    } else {
      // file is uploaded
      return (
        <AnswerFileLink
          answer={props.answer}
          file={file}
          linkContent={linkContent}
        ></AnswerFileLink>
      );
    }
  }

  const answerText = getTextFromAnswer();
  return (
    <>
      <List className={classes.fileList} dense={true}>
        {getFilesFromAnswer().map((file) => {
          return (
            <React.Fragment key={file.name}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  secondary={(file.size / (1024 * 1024)).toFixed(2) + ' MB'}
                >
                  {renderFileLink(file)}
                </ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="delete"
                    className={classes.margin}
                    onClick={() => removeFile(file.name)}
                    size="large"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          );
        })}
      </List>
      <form>
        <input
          className={classes.hiddenInput}
          id={'contained-button-file-' + props.question.id}
          data-testid="file-add"
          multiple
          type="file"
          ref={fileInput}
          onChange={() => addSelectedFiles()}
        />
        <label htmlFor={'contained-button-file-' + props.question.id}>
          <Button
            component="span"
            variant="contained"
            className={classes.button}
            size="small"
            color="primary"
            disabled={props.disabled}
          >
            <AddIcon fontSize="inherit" />
            Add File
          </Button>
        </label>
        <TextField
          id="outlined-multiline-flexible"
          label="Additional Comments"
          multiline
          fullWidth
          maxRows={1000}
          value={answerText}
          margin="normal"
          variant="outlined"
          disabled={props.disabled}
          onChange={(event) =>
            handleAnswerChange(
              QuestionTypes.TEXT,
              props.question.id,
              getAnswerBodyTextChanged(event.target.value),
              (answer) => uploadFiles(filesToUpload, answer.id)
            )
          }
        />
      </form>
    </>
  );
};

export default FileType;
