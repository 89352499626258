export const colorScheme = {
  grey0: '#182631',
  grey1: '#263540',
  grey2: '#677986',
  grey3: '#81919C',
  grey4: '#C5CED5',
  grey5: '#C5CED5',
  grey6: '#F0F3F7',
  grey7: '#FFFFFF',
  blue: '#3B6AE3',
  blueFaded: 'rgba(59, 106, 227, .7)',
};

export const globalFontFamily =
  'Nunito,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';
