import React from 'react';
import { createRoot } from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from '@auth0/auth0-react';
import CssBaseline from '@mui/material/CssBaseline';
import { PollApiProvider } from './forms/context/ApiContext';
import { getConfig } from './config';

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Auth0Provider
    domain={getConfig('REACT_APP_AUTH0_DOMAIN')}
    clientId={getConfig('REACT_APP_AUTH0_CLIENT_ID')}
    redirectUri={window.location.origin}
    audience={getConfig('REACT_APP_AUTH0_AUDIENCE')}
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens={false}
  >
    <PollApiProvider>
      <CssBaseline />
      <App />
    </PollApiProvider>
  </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
