import React, { useContext } from 'react';
import TextType from './TextType';
import YesNoType from './YesNoType';
import FileType from './FileType';
import RadioType from './RadioType';
import DoneIcon from '@mui/icons-material/Done';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import Title from '../dashboard/Title';
import Typography from '@mui/material/Typography';
import { questionStyles } from '../../layout/styleProvider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import MultipleChoiceType from './MultipleChoiceType';
import AnswerContext from '../../context/AnswerContext';

const TodoIcon = styled(LiveHelpIcon)`
  visibility: hidden;
`;

const QuestionTypes = Object.freeze({
  YES_NO: 'yesNo',
  TEXT: 'text',
  FILE: 'file',
  RADIO: 'radio',
  RADIO_VERTICAL: 'radioVertical',
  MULTIPLE_CHOICE: 'multipleChoice',
});

const Question = (props) => {
  const classes = questionStyles();
  const { answerPersistenceState } = useContext(AnswerContext);

  const areInputsDisabled = answerPersistenceState === 'saving';

  function renderYesNo() {
    return (
      <YesNoType
        question={props.question}
        answer={props.answer}
        disabled={areInputsDisabled}
      />
    );
  }

  function renderText() {
    return (
      <TextType
        question={props.question}
        answer={props.answer}
        disabled={areInputsDisabled}
      />
    );
  }

  function renderFile() {
    return (
      <FileType
        question={props.question}
        answer={props.answer}
        disabled={areInputsDisabled}
      />
    );
  }

  function renderRadio(vertical = false) {
    return (
      <RadioType
        question={props.question}
        answer={props.answer}
        vertical={vertical}
        disabled={areInputsDisabled}
      />
    );
  }

  function renderMultipleChoice() {
    return (
      <MultipleChoiceType
        question={props.question}
        answer={props.answer}
        disabled={areInputsDisabled}
      />
    );
  }

  function renderQuestion(type) {
    switch (type) {
      case QuestionTypes.YES_NO: {
        return renderYesNo();
      }
      case QuestionTypes.FILE: {
        return renderFile();
      }
      case QuestionTypes.TEXT: {
        return renderText();
      }
      case QuestionTypes.RADIO: {
        return renderRadio();
      }
      case QuestionTypes.RADIO_VERTICAL: {
        return renderRadio(true);
      }
      case QuestionTypes.MULTIPLE_CHOICE: {
        return renderMultipleChoice();
      }
      default: {
        return <></>;
      }
    }
  }

  return (
    <Grid container columnSpacing={1} key={props.question.id}>
      {/* Question */}
      <Grid item xs={12} md={4} className={classes.questionGrid}>
        <div className={classes.answerStatus}>
          {props.answer ? <DoneIcon /> : <TodoIcon />}
        </div>
        {props.question.title && <Title>{props.question.title}</Title>}
        <Typography component="p">{props.question.question}</Typography>
        {props.question.description && (
          <TextField
            label="Description"
            multiline
            fullWidth
            disabled
            maxRows={1000}
            value={props.question.description}
            margin="normal"
            variant="outlined"
          />
        )}
      </Grid>
      {/* Answer */}
      <Grid item xs={12} md={8}>
        {renderQuestion(props.question.type)}
      </Grid>
    </Grid>
  );
};

export { Question as default, QuestionTypes };
